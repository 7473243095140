import { PrimeIcons } from 'primereact/api';
import { MenuItem } from 'primereact/menuitem';
import { ScrollTop } from 'primereact/scrolltop';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { KmpSpeedDial } from '../KmpSpeedDial';
import { LogoIcons } from '../LogoIcons';
import { Sharer } from '../Sharer';
import Footer from './footer';
import Topbar from './topbar';

const speedDialItemTemplate = (item: MenuItem) => (
  <a
    role="menuitem"
    className={'p-speeddial-action ' + item.className}
    style={item.style}
    href={item.url || '#'}
    target={item.target}
    data-pr-tooltip={item.label}
    onClick={e => {
      if (item.command) {
        e.preventDefault();
        e.stopPropagation();
        item.command({ originalEvent: e, item });
      }
    }}
  >
    <span className={'p-speeddial-action-icon ' + item.icon}></span>
  </a>
);

export const speedDialItems: MenuItem[] = [
  {
    label: 'Whatsapp',
    icon: LogoIcons.WHATSAPP,
    url: 'https://wa.me/6289623498800/',
    target: '_blank',
    className: 'p-shadow-3',
    style: { backgroundColor: 'var(--color-whatsapp)' },
    template: speedDialItemTemplate,
  },
  {
    label: 'Telepon',
    icon: PrimeIcons.PHONE,
    url: 'tel:622742820302',
    target: '_blank',
    className: 'p-shadow-3',
    style: { backgroundColor: 'var(--blue-500)' },
    template: speedDialItemTemplate,
  },
  {
    label: 'Email',
    icon: PrimeIcons.ENVELOPE,
    url: 'mailto:contact.digipeta@gmail.com',
    target: '_blank',
    className: 'p-shadow-3',
    style: { backgroundColor: 'var(--yellow-500)' },
    template: speedDialItemTemplate,
  },
];

class Layout extends Component<RouteComponentProps> {
  render() {
    const isDesktop = window.innerWidth > 768;

    return (
      <React.Fragment>
        <Topbar />
        {this.props.children}
        <Footer />
        <Tooltip target=".custom-speeddial .p-speeddial-action" />
        <Tooltip target=".custom-speeddial .p-speeddial-button" content="Hubungi Kami" />
        <KmpSpeedDial
          model={speedDialItems}
          direction="up"
          className="custom-speeddial"
          style={{ position: 'fixed', left: 20, bottom: 20, zIndex: 99 }}
          buttonClassName="p-shadow-3"
          buttonStyle={{
            width: '3rem',
            height: '3rem',
            backgroundColor: 'var(--green-500)',
            borderColor: 'var(--green-500)',
          }}
          showIcon={PrimeIcons.COMMENT}
          hideIcon={PrimeIcons.TIMES}
        />
        <Sharer
          text="auto"
          url="auto"
          direction={isDesktop ? 'right' : 'up'}
          tooltip="Bagikan halaman ini"
          tooltipOptions={{ position: isDesktop ? 'top' : 'right' }}
          style={{ position: 'fixed', left: 80, bottom: 20, zIndex: 99 }}
          buttonClassName="p-shadow-3"
          buttonStyle={{ width: '3rem', height: '3rem' }}
          itemClassName="p-shadow-3"
        />
        <ScrollTop threshold={200} behavior="smooth" className="custom-scrolltop" />
      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
