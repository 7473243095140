import axios from 'axios';

import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Pagination, Post, PostTaxonomy } from '../../components/models';

export interface PostState {
  posts: Post[];
  categories: PostTaxonomy[];
  tags: PostTaxonomy[];
  currentPost?: Post;
  error?: any;
}

export interface PostRequestParam {
  limit?: number;
  offset?: number;
  type?: string;
  cat?: number;
  tag?: number;
  order?: string;
  search?: string;
}

type PostResponse = Post[] | Pagination<Post>;

const apiBaseUrl = process.env.REACT_APP_API_URL;
export const fetchPosts = createAsyncThunk<PostResponse, PostRequestParam>(
  'post/fetchAll',
  async (params: PostRequestParam) => {
    const res = await axios.get<PostResponse>(`${apiBaseUrl}/post/`, { params });
    return res.data;
  }
);

export const fetchPost = createAsyncThunk('post/fetchSingle', async (slug: string, thunkApi) => {
  const state = (thunkApi.getState() as any).post as PostState;
  const post = state.posts.find(p => p.slug === slug);
  if (post) return post;
  const res = await axios.get<Post>(`${apiBaseUrl}/post/${slug}/`);
  return res.data;
});

export const fetchCategories = createAsyncThunk('post/fetchCategories', async () => {
  const res = await axios.get<PostTaxonomy[]>(`${apiBaseUrl}/category/`);
  return res.data;
});

export const fetchTags = createAsyncThunk('post/fetchTags', async () => {
  const res = await axios.get<PostTaxonomy[]>(`${apiBaseUrl}/tag/`);
  return res.data;
});

const initialState: PostState = {
  posts: [],
  categories: [],
  tags: [],
};

export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    setCurrentPost(state, action: PayloadAction<Post>) {
      state.currentPost = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchPosts.fulfilled, (state, action) => {
      const existingIds = state.posts.map(post => post.id);
      const posts = Array.isArray(action.payload) ? action.payload : action.payload.results;
      state.posts = state.posts.concat(posts.filter(post => !existingIds.includes(post.id)));
    });

    builder.addCase(fetchPost.fulfilled, (state, action) => {
      state.currentPost = action.payload;
      state.error = undefined;
    });

    builder.addCase(fetchPost.rejected, (state, action) => {
      state.error = action.error;
    });

    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
    });

    builder.addCase(fetchTags.fulfilled, (state, action) => {
      state.tags = action.payload;
    });
  },
});

export const postReducer = postSlice.reducer;
