export enum LogoIcons {
  AMAZON = 'li li-amazon',
  AMPLIFY = 'li li-amplify',
  ANDROID = 'li li-android',
  ANGULAR = 'li li-angular',
  APPLE = 'li li-apple',
  APPLE_APPSTORE = 'li li-apple-appstore',
  BITBUCKET = 'li li-bitbucket',
  BITCOIN = 'li li-bitcoin',
  BUFFER = 'li li-buffer',
  CAPACITOR = 'li li-capacitor',
  CHROME = 'li li-chrome',
  CLOSED_CAPTIONING = 'li li-closed-captioning',
  CODEPEN = 'li li-codepen',
  CSS3 = 'li li-css3',
  DESIGNERNEWS = 'li li-designernews',
  DRIBBBLE = 'li li-dribbble',
  DROPBOX = 'li li-dropbox',
  EDGE = 'li li-edge',
  ELECTRON = 'li li-electron',
  EURO = 'li li-euro',
  FACEBOOK = 'li li-facebook',
  FIREBASE = 'li li-firebase',
  FIREFOX = 'li li-firefox',
  FLICKR = 'li li-flickr',
  FOURSQUARE = 'li li-foursquare',
  GITHUB = 'li li-github',
  GOOGLE = 'li li-google',
  GOOGLE_PLAYSTORE = 'li li-google-playstore',
  HACKERNEWS = 'li li-hackernews',
  HTML5 = 'li li-html5',
  INSTAGRAM = 'li li-instagram',
  IONIC = 'li li-ionic',
  IONITRON = 'li li-ionitron',
  JAVASCRIPT = 'li li-javascript',
  LARAVEL = 'li li-laravel',
  LINKEDIN = 'li li-linkedin',
  MARKDOWN = 'li li-markdown',
  NODEJS = 'li li-nodejs',
  NO_SMOKING = 'li li-no-smoking',
  NPM = 'li li-npm',
  OCTOCAT = 'li li-octocat',
  PINTEREST = 'li li-pinterest',
  PLAYSTATION = 'li li-playstation',
  PWA = 'li li-pwa',
  PYTHON = 'li li-python',
  REACT = 'li li-react',
  REDDIT = 'li li-reddit',
  RSS = 'li li-rss',
  SASS = 'li li-sass',
  SKYPE = 'li li-skype',
  SLACK = 'li li-slack',
  SNAPCHAT = 'li li-snapchat',
  STACKOVERFLOW = 'li li-stackoverflow',
  STEAM = 'li li-steam',
  STENCIL = 'li li-stencil',
  TUMBLR = 'li li-tumblr',
  TUX = 'li li-tux',
  TWITCH = 'li li-twitch',
  TWITTER = 'li li-twitter',
  USD = 'li li-usd',
  VIMEO = 'li li-vimeo',
  VK = 'li li-vk',
  VUE = 'li li-vue',
  WEB_COMPONENT = 'li li-web-component',
  WHATSAPP = 'li li-whatsapp',
  WINDOWS = 'li li-windows',
  WORDPRESS = 'li li-wordpress',
  XBOX = 'li li-xbox',
  XING = 'li li-xing',
  YAHOO = 'li li-yahoo',
  YEN = 'li li-yen',
  YOUTUBE = 'li li-youtube',
}
