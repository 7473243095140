import { SpeedDial, SpeedDialProps } from 'primereact/speeddial';
import React, { Component, createRef } from 'react';
import { findDOMNode } from 'react-dom';

export interface State {
  visible: boolean;
}

/**
 * Non blocking SpeedDial
 */
export class KmpSpeedDial extends Component<SpeedDialProps, State> {
  state: State = {
    visible: !!this.props.visible,
  };

  private _ref = createRef<SpeedDial>();

  private get _listElement() {
    const rootEl = findDOMNode(this._ref.current) as HTMLElement;
    return rootEl?.querySelector<HTMLElement>('.p-speeddial-list');
  }

  componentDidMount() {
    if (!this.props.visible) {
      const listEl = this._listElement;
      if (listEl) listEl.style.display = 'none';
    }
  }

  componentDidUpdate(prevProps: SpeedDialProps) {
    const speedDial = this._ref.current;
    if (prevProps.visible !== this.props.visible && speedDial) {
      if (this.props.visible) speedDial.show();
      else speedDial.hide();
    }
  }

  render() {
    const props: SpeedDialProps = {
      onVisibleChange: this._onVisibleChange,
      ...this.props,
    };
    props.visible = this.state.visible;

    return <SpeedDial ref={this._ref} {...props} />;
  }

  private _onVisibleChange = (visible: boolean) => {
    const listEl = this._listElement;
    if (visible) {
      if (listEl) listEl.style.display = 'flex';
      setTimeout(() => {
        this.setState({ visible });
      });
    } else
      this.setState({ visible }, () => {
        if (!visible)
          setTimeout(() => {
            if (listEl) listEl.style.display = 'none';
          }, 120);
      });
  };
}
