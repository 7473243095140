import { Button } from 'primereact/button';
import React from 'react';

import { dataFooter } from '../models';

export interface State {
  logo: string;
  logo2: string;
  description: string;
  downloads: typeof dataFooter.downloads;
  copyrights: string;
  quicklinks: typeof dataFooter.quicklinks;
  contacts: typeof dataFooter.contacts;
  socials: typeof dataFooter.socials;
}

class Footer extends React.Component<{}, State> {
  state: State = {
    logo: dataFooter.logo,
    logo2: dataFooter.logo2,
    description: dataFooter.description,
    downloads: dataFooter.downloads,
    copyrights: dataFooter.copyrights,
    quicklinks: dataFooter.quicklinks,
    contacts: dataFooter.contacts,
    socials: dataFooter.socials,
  };

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="footer-content container">
            <div className="p-grid">
              <div className="p-col-12 p-lg-4">
                <div className="footer-logo p-pt-0 p-pb-4">
                  <img src={this.state.logo} alt={this.state.copyrights} />
                </div>
                <p className="description">{this.state.description}</p>
                {/* <div className="footer-logo-second p-pt-4 p-pb-0">
                  <a href='https://karomap.com/' target="_blank" rel="noopener noreferrer">
                    <img src={this.state.logo2} alt={this.state.copyrights} />
                  </a>
                </div> */}
              </div>
              <div className="quicklinks p-col-12 p-lg-3">
                <h1>Tautan</h1>
                <div className="content">
                  <ul>
                    {this.state.quicklinks.map((data, idx) => (
                      <li key={idx}>
                        <a href={data.url} target="_blank" rel="noopener noreferrer">
                          {data.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="contacts p-col-12 p-lg-5">
                <h1>Kontak</h1>
                <div className="content">
                  <ul>
                    {this.state.contacts.map((data, idx) => (
                      <li key={idx}>
                        {data.name} :{' '}
                        {data.url ? (
                          <a href={data.url} target="_blank" rel="noreferrer">
                            {data.description}
                          </a>
                        ) : (
                          data.description
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="bottom-bar">
          <div className="p-grid container">
            <div className="p-col-12 p-lg-5 copyright">
              <p>
                &copy; {new Date().getFullYear()} {this.state.copyrights}
              </p>
            </div>
            <div className="p-col-12 p-lg-7 social-media p-grid p-justify-end d-none">
              {this.state.socials.map((data, idx) => (
                <a key={idx} href={data.url} target="_blank" rel="noopener noreferrer">
                  <Button className="social p-p-0 p-m-1 p-mx-xl-3">
                    <i className={`pi pi-${data.icon} p-px-2`}></i>
                  </Button>
                </a>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;
