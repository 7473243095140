import { PrimeIcons } from 'primereact/api';
import { MenuItem } from 'primereact/menuitem';
import { SpeedDialDirectionType, SpeedDialType } from 'primereact/speeddial';
import { Tooltip } from 'primereact/tooltip';
import TooltipOptions from 'primereact/tooltip/tooltipoptions';
import React, { CSSProperties, Component, Fragment } from 'react';

import { classNames } from '../utils';
import { KmpSpeedDial } from './KmpSpeedDial';
import { LogoIcons } from './LogoIcons';

export interface Props {
  text: string;
  url: string;
  type?: SpeedDialType;
  direction?: SpeedDialDirectionType;
  className?: string;
  style?: CSSProperties;
  buttonClassName?: string;
  buttonStyle?: CSSProperties;
  itemClassName?: string;
  itemStyle?: CSSProperties;
  tooltip?: string;
  tooltipOptions?: TooltipOptions;
}

export interface ShareItem extends MenuItem {
  color: string;
}

export class Sharer extends Component<Props> {
  private _itemTemplate = (item: ShareItem) => {
    const className = classNames('p-speeddial-action', item.className, this.props.itemClassName);
    const style: CSSProperties = item.style || this.props.itemStyle || {};
    style.backgroundColor = item.color;

    return (
      <a
        role="menuitem"
        className={className}
        style={style}
        href={item.url || '#'}
        target={item.target}
        data-pr-tooltip={item.label}
        onClick={e => {
          if (item.command) {
            e.preventDefault();
            e.stopPropagation();
            item.command({ originalEvent: e, item });
          }
        }}
      >
        <span className={classNames('p-speeddial-action-icon', item.icon)}></span>
      </a>
    );
  };

  private _items: ShareItem[] = [
    {
      icon: LogoIcons.FACEBOOK,
      label: 'Facebook',
      color: 'var(--color-facebook)',
      template: this._itemTemplate,
      command: e => {
        this._share(e.item as ShareItem, 'https://www.facebook.com/sharer/sharer.php?u={url}');
      },
    },
    {
      icon: LogoIcons.TWITTER,
      label: 'Twitter',
      color: 'var(--color-twitter)',
      template: this._itemTemplate,
      command: e => {
        this._share(e.item as ShareItem, 'https://twitter.com/intent/tweet?text={text}&url={url}');
      },
    },
    {
      icon: LogoIcons.WHATSAPP,
      label: 'Whatsapp',
      color: 'var(--color-whatsapp)',
      template: this._itemTemplate,
      command: e => {
        this._share(e.item as ShareItem, 'https://wa.me/?text={text}%0A{url}');
      },
    },
    {
      icon: LogoIcons.LINKEDIN,
      label: 'LinkedIn',
      color: 'var(--color-linkedin)',
      template: this._itemTemplate,
      command: e => {
        this._share(e.item as ShareItem, 'https://www.linkedin.com/shareArticle?mini=true&url={url}');
      },
    },
    {
      icon: PrimeIcons.ENVELOPE,
      label: 'Email',
      color: 'var(--yellow-500)',
      template: this._itemTemplate,
      command: e => {
        this._share(e.item as ShareItem, 'mailto:?subject={text}&body={url}', false);
      },
    },
  ];

  render() {
    const className = classNames('speeddial-share', this.props.className);

    return (
      <Fragment>
        <Tooltip target=".speeddial-share .p-speeddial-action" {...this.props.tooltipOptions} />
        <Tooltip
          target=".speeddial-share .p-speeddial-button"
          content={this.props.tooltip}
          {...this.props.tooltipOptions}
        />
        <KmpSpeedDial
          model={this._items}
          type={this.props.type}
          direction={this.props.direction}
          className={className}
          style={this.props.style}
          buttonClassName={this.props.buttonClassName}
          buttonStyle={this.props.buttonStyle}
          showIcon={PrimeIcons.SHARE_ALT}
          hideIcon={PrimeIcons.TIMES}
        />
      </Fragment>
    );
  }

  private _share(item: ShareItem, sharerUrl: string, popup = true) {
    let url = this.props.url;
    if (url === 'auto') url = window.location.href;
    url = encodeURIComponent(url);
    let text = this.props.text;
    if (text === 'auto') text = document.title;
    text = encodeURIComponent(text);
    const targetUrl = sharerUrl.replace('{url}', url).replace('{text}', text);
    popup
      ? window.open(
          targetUrl,
          item.label,
          `toolbar=no,
          location=no,
          status=no,
          menubar=no,
          scrollbars=yes,
          resizable=yes,
          width=600,
          height=600`
        )
      : window.open(targetUrl, '_blank');
  }
}
