import { Post } from './components/models';

export function normalizeUrl(url: string) {
  return url.replace(/\\/g, '/').replace(/([^:]\/)\//g, '$1');
}

export function absoluteUrl(url: string) {
  if (url.startsWith('http')) return url;
  const appUrl = process.env.REACT_APP_URL || '/';
  return normalizeUrl(appUrl + url);
}

export function getPostDescription(html: string | HTMLElement, count = 20, byChar = false) {
  const container = document.createElement('div');
  if (typeof html === 'string') container.innerHTML = html;
  else container.append(html);
  const postBody = container.textContent.replace(/\s+/, ' ').trim();
  return byChar ? postBody.slice(0, count) : postBody.split(' ').slice(0, count).join(' ');
}

export function classNames(...args: string[]) {
  return args ? args.filter(c => !!c).join(' ') : undefined;
}

export function getPostAuthor(post: Post) {
  if (!post) return;
  const author = post.author;
  if (author.first_name) return [author.first_name, author.last_name].join(' ');
  return author.username;
}
